import axios from "axios";
var baseURL = process.env.REACT_APP_API_BASE_URL;
var apis = {
    'saveFlagAssetSTF' : `${baseURL}flagging/saveflagassetstf`,
    'getPendingIR' :`${baseURL}flagging/getpendingir`,
}
export function saveFlagAssetSTF(data) {
    return axios.put(apis.saveFlagAssetSTF,data);
}
export function getPendingIR(data) {
    return axios.put(apis.getPendingIR,data);
}

