import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import FromFieldText from '../../atoms/FromFieldText';
import {
    getControlDepartmentList,
    getLastAssignmentNo,
    getSubControlDepartmentList,
    getSubDepartmentList,
    saveAssignemnt,
    getUsersList
} from '../../services/user.service';
import * as CryptoJS from "crypto-js";
import FromFieldDate from "../../atoms/FromFieldDate";
import FromSpFieldTextArea from "../../atoms/FromSpFieldTextArea";
import FromSpFieldTextAreaHari from "../../atoms/FromSpFieldTextAreaHari";

import AWS from 'aws-sdk';
// Configure AWS with your credentials
AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    region: process.env.REACT_APP_AWS_BUCKET_REGION,
});
const s3 = new AWS.S3();

const TEXT_REGEX = /^[a-zA-Z0-9-_ ]{1,255}$/;

function CreateNewIRAssignment() {
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    const [selectedRepairs, setSelectedRepairs] = useState([]);
    const [ErrSelectedRepairs, setErrSelectedRepairs] = useState('');
    const [error, setError] = useState(false);

    const [userId, setUsersId] = useState('');
    const [ErrUserID, setErrUserId] = useState('');

    const [formCategoryId, setFormCategoryId] = useState('');
    const [ErrFormCategoryId, setErrFormCategoryId] = useState('');

    const [formControlSubDepartmentID, setFormControlSubDepartmentID] = useState('');
    const [ErrFormControlSubDepartmentID, setErrFormControlSubDepartmentID] = useState('');

    const [assignedDate, setAssignedDate] = useState('');
    const [ErrAssignedDate, setErrAssignedDate] = useState('');

    const [endUserName, setEndUserName] = useState('');
    const [ErrEndUserName, setErrEndUserName] = useState('');

    const [assignUserName, setAssignUserName] = useState('');
    const [ErrAssignUserName, setErrAssignUserName] = useState('');

    const [description, setDescription] = useState('');
    const [ErrDescription, setErrDescription] = useState('');

    const [userEmailID, setUserEmailID] = useState('');
    const [ErrUserEmailID, setErrUserEmailID] = useState('');

    const [assignmentNumber, setAssignmentNumber] = useState('');
    const [ErrAssignmentNumber, setErrAssignmentNumber] = useState('');

    const [detailProblem, setDetailProblem] = useState('');
    const [ErrDetailProblem, setErrDetailProblem] = useState('');

    const [uploadFile, setUploadFile] = useState('');
    const [uploadFileError, setUploadFileError] = useState('');

    const [uploadScreenShot, setUploadScrrenShot] = useState('');
    const [uploadScrrenShotError, setUploadScrrenShotError] = useState('');

    const [repairedIssue, setRepairedIssue] = useState('');
    const [ErrRepairedIssue, setErrRepairedIssue] = useState(false);

    const [accidentalDamage, setAccidentalDamage] = useState('');
    const [ErrAccidentalDamage, setErrAccidentalDamage] = useState(false);

    const [amcCovered, setAmcCovered] = useState('');
    const [ErrAmcCovered, setErrAmcCovered] = useState(false);

    const [showTextField, setShowTextField] = useState(false);
    const [additionalInfo, setAdditionalInfo] = useState('');
    const [ErrAdditionalInfo, setErrAdditionalInfo] = useState('');

    const [formControlDepartment, setFormControlDepartment] = useState([]);
    const [formControlSubDepartment, setFormControlSubDepartment] = useState([]);

    const [subDepartment, setSubDepartment] = useState('');
    const [ErrSubDepartment, setErrSubDepartment] = useState(false);

    const [subDepartments, setSubDepartments] = useState([]);

    const [userLists, setUserLists] = useState([]);

    const resetUserInfo = () => {
        //assignmentNumber('');
        setFormCategoryId('');
        setAssignedDate('');
        setEndUserName('');
        setUserEmailID('');
        setSelectedRepairs('');
        setFormControlSubDepartmentID('');
        setAssignUserName('');
        setDescription('');
        setUserEmailID('');
        setAssignmentNumber('');
        setDetailProblem('');
        setRepairedIssue('');
        setAccidentalDamage('');
        setAmcCovered('');
        setShowTextField('');
        setAdditionalInfo('');
        setFormControlDepartment('');
    };

    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const rfid_ID = query.get('rfid_ID');

    const subDepartmentIdFromUrl = query.get('subDeptId');
    const [isSubDepartmentsLoaded, setIsSubDepartmentsLoaded] = useState(false);

    const userIdFromUrl = query.get('userIdd');
    const [isUsersListLoaded, setIsUsersListLoaded] = useState(false);

    useEffect(() => {

        const fetchData = async () => {

            try {
                const EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                    'authToken': sessionStorage.getItem('LoggedInToken'),
                }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
                const oData = {'token': EncryptData};

                const res = await getControlDepartmentList(oData);
                if (res?.data?.data?.length) setFormControlDepartment(res.data.data);

                const res2 = await getSubControlDepartmentList(oData);
                if (res2?.data?.data?.length) setFormControlSubDepartment(res2.data.data);

                getLastAssignmentNo(oData).then(res => {

                    let lastDinId = ((res.data.data?.res[0]?.id) ? res.data.data.res[0].id : 0) + 1;
                    let newlastDinId = lastDinId.toString().padStart(4, '0');
                    let currentYear = new Date().getFullYear();
                    let divisionCode = sessionStorage.LoggedInDivisionCode;

                    setAssignmentNumber('ERAI' + divisionCode + '-' + currentYear + newlastDinId);
                });

            } catch (error) {
                console.error("Error fetching company profile list: ", error);
            }

        };

        fetchData();
    }, [rfid_ID]);

    useEffect(() => {
        if (formCategoryId && formCategoryId !== '') {
            const EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': { 'department_id': formCategoryId },
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

            const oData = { 'token': EncryptData };

            getSubDepartmentList(oData).then(res => {
                if (res?.data?.data?.length) {
                    setSubDepartments(res.data.data);
                } else {
                    setSubDepartments([]);
                }
            }).catch(() => {
                setSubDepartments([]);
            });
        }
    }, [formCategoryId]);

    useEffect(() => {
        if (subDepartment && subDepartment !== '') {
            const EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': { 'sub_department_id': subDepartment },
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

            const oData = { 'token': EncryptData };

            getUsersList(oData).then(res => {
                if (res?.data?.data?.length) {
                    setUserLists(res.data.data); // Set user list
                    setIsUsersListLoaded(true);
                } else {
                    setUserLists([]); // Clear list if no data
                }
            }).catch(() => {
                setUserLists([]); // Handle errors
            });
        }
    }, [subDepartment]);

    const handleControlDepartment = (e) => {
        const departmentId = e.target.value;
        setFormCategoryId(departmentId); // Set department ID

        if (departmentId === '') {
            setSubDepartments([]); // Clear sub-departments if no department selected
            setUserLists([]); // Clear users list
        }
    };

    const handleSubDepartment = (e) => {
        const subDepartmentId = e.target.value;
        setSubDepartment(subDepartmentId); // Set selected sub-department ID

        if (subDepartmentId === '') {
            setUserLists([]); // Clear users if no sub-department selected
        }
    };

// Handle user selection (update only the selected user ID)
    const handleUsersList = (e) => {
        const selectedUserId = e.target.value;
        setUsersId(selectedUserId); // Correctly update the userId state
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let valid = true;

        if (!formCategoryId) {
            setErrFormCategoryId('Control department is required');
            valid = false;
        } else {
            setErrFormCategoryId('');
        }
        if (!formControlSubDepartmentID) {
            setErrFormControlSubDepartmentID('Sub Control Department is required');
            valid = false;
        } else {
            setErrFormControlSubDepartmentID('');
        }
        if (!assignUserName) {
            setErrAssignUserName('User Name is required');
            valid = false;
        } else {
            setErrAssignUserName('');
        }
        if (!description) {
            setErrDescription('Item Description is required');
            valid = false;
        } else {
            setErrDescription('');
        }
        if (!assignmentNumber) {
            setErrAssignmentNumber('Assignment is required');
            valid = false;
        } else {
            setErrAssignmentNumber('');
        }
        if (!assignedDate) {
            setErrAssignedDate('Assignment date is required');
            valid = false;
        } else {
            setErrAssignmentNumber('');
        }
        if (!endUserName) {
            setErrEndUserName('End User name is required');
            valid = false;
        } else {
            setErrEndUserName('');
        }
        if (!userEmailID) {
            setErrUserEmailID('End User email id is required');
            valid = false;
        } else {
            setErrUserEmailID('');
        }
        if (!detailProblem) {
            setErrDetailProblem('Enter Detailed Problem');
            valid = false;
        } else {
            setErrDetailProblem('');
        }
        if (!selectedRepairs.length) {
            setErrSelectedRepairs('Please select at least one repair');
            valid = false;
        } else {
            setErrSelectedRepairs('');
        }
        if (!repairedIssue) {
            setErrRepairedIssue('Please select yes or no');
            valid = false;
        } else {
            setErrRepairedIssue('');
        }
        if (!accidentalDamage) {
            setErrAccidentalDamage('Please select yes or no');
            valid = false;
        } else {
            setErrAccidentalDamage('');
        }
        if (!amcCovered) {
            setErrAmcCovered('Please select yes or no');
            valid = false;
        } else {
            setErrAmcCovered('');
        }
        if (amcCovered === "Yes" && !additionalInfo) {
            setErrAdditionalInfo('This field is required when AMC is covered');
            valid = true;
        } else {
            setErrAdditionalInfo('');
        }

        if (valid) {
            saveData();
        }
    };

    const handleUploadFile = (e) => {
        const files = Array.from(e.target.files); // Convert FileList to array
        const validFileTypes = ['image/jpeg', 'image/png', 'application/pdf'];
        const maxSize = 5 * 1024 * 1024; // 2 MB
        const maxFiles = 5;
        let errorMsg = '';
        let validFiles = [];

        if (files.length > maxFiles) {
            errorMsg = `You can only upload up to ${maxFiles} files.`;
        } else {
            for (let file of files) {
                if (!validFileTypes.includes(file.type)) {
                    errorMsg = 'Only JPEG, PNG, and PDF files are allowed.';
                    break;
                }
                if (file.size > maxSize) {
                    errorMsg = 'File size should not exceed 5 MB.';
                    break;
                }
                validFiles.push(file);
            }
        }

        if (errorMsg) {
            setUploadFileError(errorMsg);
            setUploadFile([]);
        } else {
            setUploadFileError('');
            setUploadFile(validFiles);
        }
    };
    const handleUploadScreenshot = (e) => {
        const files = Array.from(e.target.files); // Convert FileList to array
        const validFileTypes = ['image/jpeg', 'image/png', 'application/pdf'];
        const maxSize = 5 * 1024 * 1024; // 2 MB
        const maxFiles = 5;
        let errorMsg = '';
        let validFiles = [];

        if (files.length > maxFiles) {
            errorMsg = `You can only upload up to ${maxFiles} files.`;
        } else {
            for (let file of files) {
                if (!validFileTypes.includes(file.type)) {
                    errorMsg = 'Only JPEG, PNG, and PDF files are allowed.';
                    break;
                }
                if (file.size > maxSize) {
                    errorMsg = 'File size should not exceed 5 MB.';
                    break;
                }
                validFiles.push(file);
            }
        }

        if (errorMsg) {
            setUploadScrrenShotError(errorMsg);
            setUploadScrrenShot([]);
        } else {
            setUploadScrrenShotError('');
            setUploadScrrenShot(validFiles);
        }
    };
    const saveData = () => {
        try {


            const file = uploadFile[0];
            let img_name = '';
            if (file) {
                const fileName = file.name;

                const fileExtension = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
                let img_name = assignmentNumber + '_' + '.' + fileExtension;

                uploadFileServer(file, img_name);

            }
            const screenshot = uploadScreenShot[0];
            let screenshot_name = '';
            if (file) {
                const fileName = screenshot.name;

                const fileExtension = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
                let screenshot_name = assignmentNumber + '_' + '.' + fileExtension;

                uploadFileServer(screenshot, screenshot_name);

            }

            var obj = {
                'cat_ir': "IRAI",
                'assignment_no': assignmentNumber,
                'assignedDate': assignedDate,
                'endUser_name': endUserName,
                'endUser_emailId': userEmailID,
                'repairs': selectedRepairs,
                'problem_desc': description,
                'upload_files': img_name,
                'upload_scrrenshots': screenshot_name,
                'equipment_listed_repairs': repairedIssue,
                'issue_caused': accidentalDamage,
                'under_warrenty': amcCovered,
                'under_warrenty_details': additionalInfo,
                'control_department_id': formCategoryId,
                'sub_control_department_id': formControlSubDepartmentID,
                'assigned_user_name': assignUserName,
                'created_by': sessionStorage.getItem('LoggedInUserId'),
            }

            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': obj,
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData = {'token': EncryptData};

            saveAssignemnt(oData).then(res => {
                if (res.data.errors && res.data.errors.errno > 0) {
                    setErrMsg(res.data.message);
                    setSuccess(false);
                } else {
                    setSuccess(res.data.message);
                    resetUserInfo();
                }
            });
        } catch (err) {
            setSuccess(false);
            if (!err?.data) {
                setErrMsg('No Server Response');
            } else if (err.data?.status === 409) {
                setErrMsg('Data already exists');
            } else {
                setErrMsg('Insert/Update Failed');
            }
        }
    };

    const uploadFileServer = (file, img_name) => {
        const params = {
            Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
            Key: 'image/' + img_name, // +'.png', // Replace with your desired S3 key
            Body: file,
        };
        s3.upload(params, (err, data) => {
            if (err) {
                console.error('Error uploading file:', err);
                setErrMsg('Error uploading file');
            } else {
                console.log('File uploaded successfully:', data);
                return params.name;
            }
        });
    };

    const repairs = [
        "Touchpad", "Keyboard", "Fan", "System Board", "Optical drive",
        "AC Adapter", "Hinge", "CD-ROM", "Printer", "Application problem",
        "No power", "Won't boot (software)", "Other", "Mouse", "LCD",
        "Hard Drive", "Unit was reloaded", "Heat sink", "CPU", "RAM memory",
        "Removable drive", "Network card", "Virus", "Won't boot (hardware)"
    ];

    const handleCheckboxChange = (repair) => {
        if (selectedRepairs.includes(repair)) {
            setSelectedRepairs(selectedRepairs.filter(item => item !== repair));
        } else {
            setSelectedRepairs([...selectedRepairs, repair]);
        }
        setError(false);
    };

    const handleRepairedIssue = (e) => {
        setRepairedIssue(e.target.value);
    }
    const handleAccidentalDamage = (e) => {
        setAccidentalDamage(e.target.value);
    }
    const handleAMC = (event) => {
        const value = event.target.value;
        setAmcCovered(value);

        // Show text field only if "Yes" is selected
        setShowTextField(value === "Yes");
    };

    return (
        <div className="container">
            <div className="row">
                <div className="col-6">
                    <h5>Create New IR Assignment</h5>
                </div>
                <div className="invalid-feedback" role="alert">
                    <i className="fa fa-info-circle"></i>
                    Please Fill all mandatory fields.
                </div>

                {success ? (
                    <div className="alert alert-success" role="alert">
                        <i className="bi bi-check-circle-fill"></i>{success}
                    </div>
                ) : (
                    errMsg && (
                        <div className="alert alert-danger" role="alert">
                            <i className="bi bi-exclamation-triangle-fill"></i>{errMsg}
                        </div>
                    )
                )}
            </div>

            <div className='row'>
                <div className='col-12'>&nbsp;</div>
            </div>

            <form onSubmit={handleSubmit}>

                <div className="row mt-2">

                    <div className='col-md-6'>
                        <FromFieldDate
                            label="Assigned Date" star="*" placeholder="YYYY-MM-DD"
                            inputValue={assignedDate}
                            setInputValue={setAssignedDate}
                            setErrorMsg={ErrAssignedDate}
                        />
                    </div>
                    <div className="col-6">
                        <FromFieldText
                            readOnly={true}
                            star="*"
                            label="Assignment Number"
                            inputValue={assignmentNumber}
                            setInputValue={setAssignmentNumber}
                            setErrorMsg={setErrAssignmentNumber}
                            errorMsg={ErrAssignmentNumber}
                        />
                    </div>
                </div>

                <div className="row mt-2">

                    <div className='col-md-6'>
                        <FromFieldText
                            star="*"
                            label="End User Email ID"
                            placeholder="End User Email ID"
                            inputValue={userEmailID}
                            setInputValue={setUserEmailID}
                            setErrorMsg={ErrUserEmailID}
                        />
                    </div>
                    <div className="col-6">
                        <FromFieldText
                            star="*"
                            label="End User Name"
                            placeholder="End User Name"
                            inputValue={endUserName}
                            setInputValue={setEndUserName}
                            setErrorMsg={ErrEndUserName}
                        />
                    </div>
                </div>

                <div className="row mt-3">
                    <h6 className="text-center">List of Equipment</h6>
                    <table className="table table-bordered">
                        <thead className="text-center">
                        <tr>
                            <th>Sl #</th>
                            <th>TAG No</th>
                            <th>TAG Name</th>
                            <th>Item Description</th>
                        </tr>
                        </thead>
                        <tbody className="text-center">
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>
                                <div className="d-flex justify-content-center col-md-8">
                                    <input type="text" className="form-control"
                                           placeholder="Description"
                                           inputValue={description}
                                           setInputValue={setDescription}
                                           setErrorMsg={ErrDescription}
                                    />
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div className="p-3">
                    <label><strong>Please Select the Repairs<span className="error">*</span></strong></label>
                    <div className="row">
                        {repairs.map((repair, index) => (
                            <div className="col-6 col-md-4 mb-2" key={index}>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id={`repair-${index}`}
                                        onChange={() => handleCheckboxChange(repair)}
                                        checked={selectedRepairs.includes(repair)}
                                    />
                                    <label className="form-check-label" htmlFor={`repair-${index}`}>
                                        {repair}
                                    </label>
                                </div>
                            </div>
                        ))}
                    </div>
                    {ErrSelectedRepairs && (
                        <div className="text-danger">{ErrSelectedRepairs}</div>
                    )}
                </div>

                <div className="row mt-3">
                    <div className="col-md-6">
                        <label> Was the equipment listed above been repaired for the same issue? <span
                            className="error">*</span></label>
                        <span className="star error">  </span>
                    </div>
                    <div className="col-md-3">
                        <select onChange={handleRepairedIssue} name="alternative_verification"
                                className="form-select form-select-sm">
                            <option value="">Select Repaired Issue</option>
                            <option value="Yes"
                                    selected={repairedIssue === "Yes" ? "selected" : ''}>Yes
                            </option>
                            <option value="No"
                                    selected={repairedIssue === "No" ? "selected" : ''}>No
                            </option>
                        </select>
                        <span className="invalid-feedback">{ErrRepairedIssue} </span>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-6">
                        <label> Was the issue caused by accidental damage like water spill, equipment was
                            dropped?<span className="error">*</span></label>
                        <span className="star error">  </span>
                    </div>
                    <div className="col-md-3">
                        <select onChange={handleAccidentalDamage} name="alternative_verification"
                                className="form-select form-select-sm">
                            <option value="">Select Accidental Damage</option>
                            <option value="Yes"
                                    selected={accidentalDamage === "Yes" ? "selected" : ''}>Yes
                            </option>
                            <option value="No"
                                    selected={accidentalDamage === "No" ? "selected" : ''}>No
                            </option>
                        </select>
                        <span className="invalid-feedback">{ErrAccidentalDamage} </span>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-6">
                        <label>Is asset covered under warranty/AMC/Insurance?<span className="error">*</span></label>
                        <span className="star error"></span>
                    </div>
                    <div className="col-md-3">
                        <select onChange={handleAMC} name="amcCovered" className="form-select form-select-sm">
                            <option value="">Select Accidental Damage</option>
                            <option value="Yes" selected={amcCovered === "Yes"}>Yes</option>
                            <option value="No" selected={amcCovered === "No"}>No</option>
                        </select>
                        <span className="invalid-feedback">{ErrAmcCovered}</span>
                    </div>
                </div>

                {/* Additional Information div */}
                {showTextField && (
                    <div className="row mt-3">
                        <div className="col-md-6">
                            <label>Additional Information</label>
                        </div>
                        <div className="col-md-6">
                            <FromSpFieldTextAreaHari
                                maxLength={2000}
                                numRows={5}
                                className="form-control"
                                inputValue={additionalInfo}
                                setInputValue={setAdditionalInfo}
                                onChange={(e) => setAdditionalInfo(e.target.value)}
                                errorMsg={ErrAdditionalInfo}
                            />
                            <span className="invalid-feedback">{ErrAdditionalInfo}</span>
                        </div>
                    </div>
                )}
                <div className="row mt-3">
                    <div className="col-md-6">
                        <label>Brief description of the problem<span className="error">*</span></label>
                    </div>
                    <div className='col-md-6'>
                        <FromSpFieldTextAreaHari maxLength="2000" numRows="5"
                                                 inputValue={detailProblem}
                                                 setInputValue={setDetailProblem}
                                                 setErrorMsg={ErrDetailProblem}
                        />
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-12">
                            <label>Upload Files of the Problem (if applicable)</label>
                            <br/>
                            <small className="form-text text-muted">Only JPEG, PNG, and PDF files are allowed. Maximum
                                size: 5 MB. Maximum 5 files allowed at
                                once. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</small>
                            <input type="file" name="uploadFile" multiple onChange={handleUploadFile}/>
                            <span className="invalid-feedback">{uploadFileError}</span>
                        </div>


                        <div className="col-md-12 mt-3">
                            <label>Upload Screenshots of the Problem (if applicable) &nbsp;&nbsp;&nbsp;&nbsp;</label>
                            <br/>
                            <small className="form-text text-muted">Only JPEG, PNG, and PDF files are allowed. Maximum
                                size: 5 MB. Maximum 5 files allowed
                                once.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</small>
                            <input type="file" name='uploadScreenshot' multiple onChange={handleUploadScreenshot}/>
                            <span className="invalid-feedback"> {uploadScrrenShotError} </span>
                        </div>

                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-md-12">

                    </div>

                </div>

                <div className="row mt-3">
                    <div className="label col-6">
                        <label>Assignment given to: Select Control Dept </label><span className="star error"> *</span>
                    </div>
                    <div className="item col-3">
                        <select className="form-select form-select-sm" onChange={handleControlDepartment}
                                value={formCategoryId}>
                            <option value="">Select Control Dept</option>
                            {formControlDepartment && formControlDepartment.length > 0 ? (
                                formControlDepartment.map((option) => (
                                    <option key={option.id} value={option.id}>
                                        {option.department_name}
                                    </option>
                                ))
                            ) : (
                                <option value="">No Data</option>
                            )}
                        </select>
                        <span className="invalid-feedback"> {ErrFormCategoryId} </span>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="label col-6">
                        <label>Select Sub Control Dept </label><span className="star error"> *</span>
                    </div>
                    <div className="item col-3">
                        <select value={subDepartment} onChange={handleSubDepartment}
                                className="form-select form-select-sm">
                            <option value="">Select Sub Control Department</option>
                            {subDepartments && subDepartments.length > 0 ? (
                                subDepartments.map((option) => (
                                    <option key={option.id} value={option.id}>
                                        {option.sub_department_name}
                                    </option>
                                ))
                            ) : (
                                <option value="">No Data</option>
                            )}
                        </select>
                        <span className="invalid-feedback"> {ErrSubDepartment} </span>
                        <span className="invalid-feedback"> {ErrFormControlSubDepartmentID} </span>
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-md-6">
                        <label>Assign User Name<span className="error">*</span></label>
                        <span className="star error">  </span>
                    </div>
                    <div className="item col-3">
                        <select value={userId} onChange={handleUsersList} className="form-select form-select-sm">
                            <option value="">Select User</option>
                            {userLists && userLists.length > 0 ? (
                                userLists.map((option) => (
                                    <option key={option.id} value={option.id}>
                                        {option.name} / {option.email}
                                    </option>
                                ))
                            ) : (
                                <option value="">No Data</option>
                            )}
                        </select>
                        <span className="invalid-feedback"> {ErrUserID} </span>
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col-md-12 d-flex justify-content-center">
                        <button type="submit" className="btn btn-primary btn-sm">Create Assignment</button>
                    </div>
                </div>

            </form>
        </div>
    );
}

export default CreateNewIRAssignment;