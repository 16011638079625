import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import FromFieldText from '../../atoms/FromFieldText';
import {getLastERAssignmentNoOthers, saveERAssignemnt} from '../../services/user.service';
import * as CryptoJS from "crypto-js";
import FromFieldDate from "../../atoms/FromFieldDate";
import FromSpFieldTextArea from "../../atoms/FromSpFieldTextArea";
import FromSpFieldTextAreaHari from "../../atoms/FromSpFieldTextAreaHari";

import AWS from 'aws-sdk';
// Configure AWS with your credentials
AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    region: process.env.REACT_APP_AWS_BUCKET_REGION,
});
const s3 = new AWS.S3();

const TEXT_REGEX = /^[a-zA-Z0-9-_ ]{1,255}$/;

function CreateNewIRAssignmentOthers() {
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    const [selectedRepairs, setSelectedRepairs] = useState([]);
    const [ErrSelectedRepairs, setErrSelectedRepairs] = useState('');
    const [error, setError] = useState(false);

    const [assignedDate, setAssignedDate] = useState('');
    const [ErrAssignedDate, setErrAssignedDate] = useState('');

    const [returnDate, setReturnDate] = useState('');
    const [ErrReturnDate, setErrReturnDate] = useState('');

    const [servicesList, setServicesList] = useState('');
    const [ErrServicesList, setErrServicesList] = useState('');

    const [serviceProviderAddress, setServiceProviderAddress] = useState('');
    const [ErrServiceProviderAddress, setErrServiceProviderAddress] = useState('');

    const [endUserName, setEndUserName] = useState('');
    const [ErrEndUserName, setErrEndUserName] = useState('');

    const [serviceProviderName, setServiceProviderName] = useState('');
    const [ErrServiceProviderName, setErrServiceProviderName] = useState('');

    const [contactPersonName, setContactPersonName] = useState('');
    const [ErrContactPersonName, setErrContactPersonName] = useState('');

    const [contactNumber, setContactNumber] = useState('');
    const [ErrContactNumber, setErrContactNumber] = useState('');

    const [description, setDescription] = useState('');
    const [ErrDescription, setErrDescription] = useState('');

    const [endUserEmailID, setEndUserEmailID] = useState('');
    const [ErrEndUserEmailID, setErrEndUserEmailID] = useState('');

    const [userEmailID, setUserEmailID] = useState('');
    const [ErrUserEmailID, setErrUserEmailID] = useState('');

    const [userMobileNo, setUserMobileNo] = useState('');
    const [ErrUserMobileNo, setErrUserMobileNo] = useState('');

    const [assignmentNumber, setAssignmentNumber] = useState('');
    const [ErrAssignmentNumber, setErrAssignmentNumber] = useState('');

    const [detailProblem, setDetailProblem] = useState('');
    const [ErrDetailProblem, setErrDetailProblem] = useState('');

    const [uploadFile, setUploadFile] = useState('');
    const [uploadFileError, setUploadFileError] = useState('');

    const [uploadScreenShot, setUploadScrrenShot] = useState('');
    const [uploadScrrenShotError, setUploadScrrenShotError] = useState('');

    const [repairedIssue, setRepairedIssue] = useState('');
    const [ErrRepairedIssue, setErrRepairedIssue] = useState(false);

    const [accidentalDamage, setAccidentalDamage] = useState('');
    const [ErrAccidentalDamage, setErrAccidentalDamage] = useState(false);

    const [amcCovered, setAmcCovered] = useState('');
    const [ErrAmcCovered, setErrAmcCovered] = useState(false);

    const [estimatedCost, setEstimatedCost] = useState('');
    const [ErrEstimatedCost, setErrEstimatedCost] = useState(false);

    const [showTextField, setShowTextField] = useState(false);
    const [additionalInfo, setAdditionalInfo] = useState('');
    const [ErrAdditionalInfo, setErrAdditionalInfo] = useState('');
    const resetUserInfo = () => {
        setAssignedDate('');
        setServiceProviderName('');
        setEndUserName('');
        setUserEmailID('');
        setSelectedRepairs('');
        setServicesList('');
        setDescription('');
        setUserEmailID('');
        setAssignmentNumber('');
        setDetailProblem('');
        setRepairedIssue('');
        setAccidentalDamage('');
        setAmcCovered('');
        setShowTextField('');
        setAdditionalInfo('');
        setEstimatedCost('');

    };

    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const rfid_ID = query.get('rfid_ID');

    useEffect(() => {

        const fetchData = async () => {

            try {
                const EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                    'authToken': sessionStorage.getItem('LoggedInToken'),
                }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
                const oData = {'token': EncryptData};
                getLastERAssignmentNoOthers(oData).then(res => {

                    let lastDinId = ((res.data.data?.res[0]?.id) ? res.data.data.res[0].id : 0) + 1;
                    let newlastDinId = lastDinId.toString().padStart(4, '0');
                    let currentYear = new Date().getFullYear();
                    let divisionCode = sessionStorage.LoggedInDivisionCode;

                    setAssignmentNumber('ERAO' + divisionCode + '-' + currentYear + newlastDinId);
                });

            } catch (error) {
                console.error("Error fetching company profile list: ", error);
            }

        };

        fetchData();
    }, [rfid_ID]);

    const handleServices = (e) => {
        setServicesList(e.target.value);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        let valid = true;
        let errorMessages = [];  // To store error messages

        if (!servicesList) {
            setErrServicesList('Service name is required');
            errorMessages.push('Service name is required');
            valid = false;
        } else {
            setErrServicesList('');
        }

        if (!serviceProviderName) {
            setErrServiceProviderName('Service provider Name is required');
            errorMessages.push('Service provider Name is required');
            valid = false;
        } else {
            setErrServiceProviderName('');
        }

        if (!serviceProviderAddress) {
            setErrServiceProviderAddress('Service provider address is required');
            errorMessages.push('Service provider address is required');
            valid = false;
        } else {
            setErrServiceProviderAddress('');
        }

        if (!contactPersonName) {
            setErrContactPersonName('Contact Person Name is required');
            errorMessages.push('Contact Person Name is required');
            valid = false;
        } else {
            setErrContactPersonName('');
        }

        if (!contactNumber) {
            setErrContactNumber('Contact Person number is required');
            errorMessages.push('Contact Person number is required');
            valid = false;
        } else if (!/^\d{10}$/.test(contactNumber)) {
            setErrContactNumber('Please enter a valid 10-digit mobile number');
            errorMessages.push('Please enter a valid 10-digit mobile number');
            valid = false;
        } else {
            setErrContactNumber('');
        }

        if (!userMobileNo) {
            setErrUserMobileNo('Mobile number is required');
            errorMessages.push('Mobile number is required');
            valid = false;
        } else if (!/^\d{10}$/.test(userMobileNo)) {
            setErrUserMobileNo('Please enter a valid 10-digit mobile number');
            errorMessages.push('Please enter a valid 10-digit mobile number');
            valid = false;
        } else {
            setErrUserMobileNo('');
        }

        if (!endUserEmailID) {
            setErrEndUserEmailID('End user email is required');
            errorMessages.push('End user email is required');
            valid = false;
        } else {
            setErrEndUserEmailID('');
        }

        if (!assignmentNumber) {
            setErrAssignmentNumber('Assignment is required');
            errorMessages.push('Assignment is required');
            valid = false;
        } else {
            setErrAssignmentNumber('');
        }

        if (!assignedDate) {
            setErrAssignedDate('Assignment date is required');
            errorMessages.push('Assignment date is required');
            valid = false;
        } else {
            setErrAssignedDate('');
        }

        if (!endUserName) {
            setErrEndUserName('End User name is required');
            errorMessages.push('End User name is required');
            valid = false;
        } else {
            setErrEndUserName('');
        }if (!estimatedCost) {
            setErrEstimatedCost('End User name is required');
            errorMessages.push('End User name is required');
            valid = false;
        } else {
            setErrEstimatedCost('');
        }

        if (!userEmailID) {
            setErrUserEmailID('User email id is required');
            errorMessages.push('User email id is required');
            valid = false;
        } else {
            setErrUserEmailID('');
        }

        if (!returnDate) {
            setErrReturnDate('Expected Date is required');
            errorMessages.push('Expected Date is required');
            valid = false;
        } else {
            setErrReturnDate('');
        }

        if (!detailProblem) {
            setErrDetailProblem('Enter Detailed Problem');
            errorMessages.push('Enter Detailed Problem');
            valid = false;
        } else {
            setErrDetailProblem('');
        }

        if (!selectedRepairs.length) {
            setErrSelectedRepairs('Please select at least one repair');
            errorMessages.push('Please select at least one repair');
            valid = false;
        } else {
            setErrSelectedRepairs('');
        }

        if (!repairedIssue) {
            setErrRepairedIssue('Please select yes or no');
            errorMessages.push('Please select yes or no for repaired issue');
            valid = false;
        } else {
            setErrRepairedIssue('');
        }

        if (!accidentalDamage) {
            setErrAccidentalDamage('Please select yes or no');
            errorMessages.push('Please select yes or no for accidental damage');
            valid = false;
        } else {
            setErrAccidentalDamage('');
        }

        if (!amcCovered) {
            setErrAmcCovered('Please select yes or no');
            errorMessages.push('Please select yes or no for AMC coverage');
            valid = false;
        } else {
            setErrAmcCovered('');
        }

        if (amcCovered === "Yes" && !additionalInfo) {
            setErrAdditionalInfo('This field is required when AMC is covered');
            errorMessages.push('Additional info is required when AMC is covered');
            valid = false;
        } else {
            setErrAdditionalInfo('');
        }

        // If form is not valid, print errors in alert and console
        if (!valid) {
            //console.log("Form submission errors:", errorMessages);
            alert(`Error: Please fix the following issues:\n\n${errorMessages.join('\n')}`);
        } else {
            saveData();
            //console.log("Form submitted successfully");
        }
    };

    const handleUploadFile = (e) => {
        const files = Array.from(e.target.files); // Convert FileList to array
        const validFileTypes = ['image/jpeg', 'image/png', 'application/pdf'];
        const maxSize = 5 * 1024 * 1024; // 2 MB
        const maxFiles = 5;
        let errorMsg = '';
        let validFiles = [];

        if (files.length > maxFiles) {
            errorMsg = `You can only upload up to ${maxFiles} files.`;
        } else {
            for (let file of files) {
                if (!validFileTypes.includes(file.type)) {
                    errorMsg = 'Only JPEG, PNG, and PDF files are allowed.';
                    break;
                }
                if (file.size > maxSize) {
                    errorMsg = 'File size should not exceed 5 MB.';
                    break;
                }
                validFiles.push(file);
            }
        }

        if (errorMsg) {
            setUploadFileError(errorMsg);
            setUploadFile([]);
        } else {
            setUploadFileError('');
            setUploadFile(validFiles);
        }
    };
    const handleUploadScreenshot = (e) => {
        const files = Array.from(e.target.files); // Convert FileList to array
        const validFileTypes = ['image/jpeg', 'image/png', 'application/pdf'];
        const maxSize = 5 * 1024 * 1024; // 2 MB
        const maxFiles = 5;
        let errorMsg = '';
        let validFiles = [];

        if (files.length > maxFiles) {
            errorMsg = `You can only upload up to ${maxFiles} files.`;
        } else {
            for (let file of files) {
                if (!validFileTypes.includes(file.type)) {
                    errorMsg = 'Only JPEG, PNG, and PDF files are allowed.';
                    break;
                }
                if (file.size > maxSize) {
                    errorMsg = 'File size should not exceed 5 MB.';
                    break;
                }
                validFiles.push(file);
            }
        }

        if (errorMsg) {
            setUploadScrrenShotError(errorMsg);
            setUploadScrrenShot([]);
        } else {
            setUploadScrrenShotError('');
            setUploadScrrenShot(validFiles);
        }
    };
    const saveData = () => {
        try {


            const file = uploadFile[0];
            let img_name = '';
            if (file) {
                const fileName = file.name;

                const fileExtension = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
                let img_name = assignmentNumber + '_' + '.' + fileExtension;

                uploadFileServer(file, img_name);

            }
            const screenshot = uploadScreenShot[0];
            let screenshot_name = '';
            if (file) {
                const fileName = screenshot.name;

                const fileExtension = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
                let screenshot_name = assignmentNumber + '_' + '.' + fileExtension;

                uploadFileServer(screenshot, screenshot_name);

            }

            var obj = {
                'cat_ir': "ERAO",
                'erassignment_no': assignmentNumber,
                'assignedDate': assignedDate,
                'endUser_name': endUserName,
                'endUser_emailId': endUserEmailID,
                'problem_desc': description,
                'upload_files': img_name,
                'upload_scrrenshots': screenshot_name,
                'equipment_listed_repairs': repairedIssue,
                'issue_caused': accidentalDamage,
                'under_warrenty': amcCovered,
                'under_warrenty_details': additionalInfo,
                'problem_details': detailProblem,
                'service_name': servicesList,
                'provider_name': serviceProviderName,
                'provider_address': serviceProviderAddress,
                'contact_person': contactPersonName,
                'contact_no': contactNumber,
                'contact_email': userEmailID,
                'mobile_no': userMobileNo,
                'return_date': returnDate,
                'estimated_cost': estimatedCost,
                'created_by': sessionStorage.getItem('LoggedInUserId'),
            }

            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': obj,
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData = {'token': EncryptData};

            saveERAssignemnt(oData).then(res => {
                if (res.data.errors && res.data.errors.errno > 0) {
                    setErrMsg(res.data.message);
                    setSuccess(false);
                } else {
                    setSuccess(res.data.message);
                    resetUserInfo();
                }
            });
        } catch (err) {
            setSuccess(false);
            if (!err?.data) {
                setErrMsg('No Server Response');
            } else if (err.data?.status === 409) {
                setErrMsg('Data already exists');
            } else {
                setErrMsg('Insert/Update Failed');
            }
        }
    };

    const uploadFileServer = (file, img_name) => {
        const params = {
            Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
            Key: 'image/' + img_name, // +'.png', // Replace with your desired S3 key
            Body: file,
        };
        s3.upload(params, (err, data) => {
            if (err) {
                console.error('Error uploading file:', err);
                setErrMsg('Error uploading file');
            } else {
                console.log('File uploaded successfully:', data);
                return params.name;
            }
        });
    };


    const handleRepairedIssue = (e) => {
        setRepairedIssue(e.target.value);
    }
    const handleAccidentalDamage = (e) => {
        setAccidentalDamage(e.target.value);
    }
    const handleAMC = (event) => {
        const value = event.target.value;
        setAmcCovered(value);

        // Show text field only if "Yes" is selected
        setShowTextField(value === "Yes");
    };
    return (
        <div className="container">
            <div className="row">
                <div className="col-6">
                    <h5>Create New ER Assignment Others</h5>
                </div>
                <div className="invalid-feedback" role="alert">
                    <i className="fa fa-info-circle"></i>
                    Please Fill all mandatory fields.
                </div>

                {success ? (
                    <div className="alert alert-success" role="alert">
                        <i className="bi bi-check-circle-fill"></i>{success}
                    </div>
                ) : (
                    errMsg && (
                        <div className="alert alert-danger" role="alert">
                            <i className="bi bi-exclamation-triangle-fill"></i>{errMsg}
                        </div>
                    )
                )}
            </div>

            <div className='row'>
                <div className='col-12'>&nbsp;</div>
            </div>

            <form onSubmit={handleSubmit}>

                <div className="row mt-2">

                    <div className='col-md-6'>
                        <FromFieldDate
                            label="Assigned Date" star="*" placeholder="YYYY-MM-DD"
                            inputValue={assignedDate}
                            setInputValue={setAssignedDate}
                            setErrorMsg={ErrAssignedDate}
                        />
                    </div>
                    <div className="col-6">
                        <FromFieldText
                            readOnly={true}
                            star="*"
                            label="Assignment Number"
                            inputValue={assignmentNumber}
                            setInputValue={setAssignmentNumber}
                            setErrorMsg={setErrAssignmentNumber}
                            errorMsg={ErrAssignmentNumber}
                        />
                    </div>
                </div>

                <div className="row mt-2">

                    <div className='col-md-6'>
                        <FromFieldText
                            star="*"
                            label="End User Email ID"
                            placeholder="End User Email ID"
                            inputValue={endUserEmailID}
                            setInputValue={setEndUserEmailID}
                            setErrorMsg={ErrEndUserEmailID}
                        />
                    </div>
                    <div className="col-6">
                        <FromFieldText
                            star="*"
                            label="End User Name"
                            placeholder="End User Name"
                            inputValue={endUserName}
                            setInputValue={setEndUserName}
                            setErrorMsg={ErrEndUserName}
                        />
                    </div>
                </div>

                <div className="row mt-3">
                    <h6 className="text-center">List of Equipment</h6>
                    <table className="table table-bordered">
                        <thead className="text-center">
                        <tr>
                            <th>Sl #</th>
                            <th>TAG No</th>
                            <th>TAG Name</th>
                            <th>Item Description</th>
                        </tr>
                        </thead>
                        <tbody className="text-center">
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>
                                <div className="d-flex justify-content-center col-md-8">
                                    <input type="text" className="form-control"
                                           placeholder="Description"
                                           inputValue={description}
                                           setInputValue={setDescription}
                                           setErrorMsg={ErrDescription}
                                    />
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div className="row mt-3">
                    <div className="col-md-6">
                        <label> Was the equipment listed above been repaired for the same issue? <span
                            className="error">*</span></label>
                        <span className="star error">  </span>
                    </div>
                    <div className="col-md-3">
                        <select onChange={handleRepairedIssue} name="alternative_verification"
                                className="form-select form-select-sm">
                            <option value="">Select Repaired Issue</option>
                            <option value="Yes"
                                    selected={repairedIssue === "Yes" ? "selected" : ''}>Yes
                            </option>
                            <option value="No"
                                    selected={repairedIssue === "No" ? "selected" : ''}>No
                            </option>
                        </select>
                        <span className="invalid-feedback">{ErrRepairedIssue} </span>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-6">
                        <label> Was the issue caused by accidental damage like water spill, equipment was
                            dropped?<span className="error">*</span></label>
                        <span className="star error">  </span>
                    </div>
                    <div className="col-md-3">
                        <select onChange={handleAccidentalDamage} name="alternative_verification"
                                className="form-select form-select-sm">
                            <option value="">Select Accidental Damage</option>
                            <option value="Yes"
                                    selected={accidentalDamage === "Yes" ? "selected" : ''}>Yes
                            </option>
                            <option value="No"
                                    selected={accidentalDamage === "No" ? "selected" : ''}>No
                            </option>
                        </select>
                        <span className="invalid-feedback">{ErrAccidentalDamage} </span>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-6">
                        <label>Is asset covered under warranty/AMC/Insurance?<span className="error">*</span></label>
                        <span className="star error"></span>
                    </div>
                    <div className="col-md-3">
                        <select onChange={handleAMC} name="amcCovered" className="form-select form-select-sm">
                            <option value="">Select Accidental Damage</option>
                            <option value="Yes" selected={amcCovered === "Yes"}>Yes</option>
                            <option value="No" selected={amcCovered === "No"}>No</option>
                        </select>
                        <span className="invalid-feedback">{ErrAmcCovered}</span>
                    </div>
                </div>

                {/* Additional Information div */}
                {showTextField && (
                    <div className="row mt-3">
                        <div className="col-md-6">
                            <label>Additional Information</label>
                        </div>
                        <div className="col-md-6">
                            <FromSpFieldTextAreaHari
                                maxLength={2000}
                                numRows={5}
                                className="form-control"
                                inputValue={additionalInfo}
                                setInputValue={setAdditionalInfo}
                                onChange={(e) => setAdditionalInfo(e.target.value)}
                                errorMsg={ErrAdditionalInfo}
                            />
                            <span className="invalid-feedback">{ErrAdditionalInfo}</span>
                        </div>
                    </div>
                )}
                <div className="row mt-3">
                    <div className="col-md-6">
                        <label>Brief description of the problem<span className="error">*</span></label>
                    </div>
                    <div className='col-md-6'>
                        <FromSpFieldTextAreaHari maxLength="2000" numRows="5"
                                                 inputValue={detailProblem}
                                                 setInputValue={setDetailProblem}
                                                 setErrorMsg={ErrDetailProblem}
                        />
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-12">
                            <label>Upload Files of the Problem (if applicable)</label>
                            <br/>
                            <small className="form-text text-muted">Only JPEG, PNG, and PDF files are allowed. Maximum
                                size: 5 MB. Maximum 5 files allowed at
                                once. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</small>
                            <input type="file" name="uploadFile" multiple onChange={handleUploadFile}/>
                            <span className="invalid-feedback">{uploadFileError}</span>
                        </div>


                        <div className="col-md-12 mt-3">
                            <label>Upload Screenshots of the Problem (if applicable) &nbsp;&nbsp;&nbsp;&nbsp;</label>
                            <br/>
                            <small className="form-text text-muted">Only JPEG, PNG, and PDF files are allowed. Maximum
                                size: 5 MB. Maximum 5 files allowed
                                once.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</small>
                            <input type="file" name='uploadScreenshot' multiple onChange={handleUploadScreenshot}/>
                            <span className="invalid-feedback"> {uploadScrrenShotError} </span>
                        </div>

                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-md-12">

                    </div>

                </div>

                <div className="row mt-3">
                    <div className="col-6">
                        <label>Assignment given to:</label>
                    </div>
                    <div className="col-6">
                        <label></label>
                    </div>
                </div>

                <div className="row mt-2">

                    <div className='col-md-6'>
                        <FromFieldText
                            star="*"
                            label="Service Provider Name"
                            placeholder="Service Provider Name"
                            inputValue={serviceProviderName}
                            setInputValue={setServiceProviderName}
                            setErrorMsg={ErrServiceProviderName}
                        />
                    </div>
                    <div className="col-6">
                        <FromSpFieldTextArea
                            numRows="3"
                            maxLength="200"
                            star="*"
                            label="Service Provider Address"
                            placeholder="Service Provider Address"
                            inputValue={serviceProviderAddress}
                            setInputValue={setServiceProviderAddress}
                            setErrorMsg={ErrServiceProviderAddress}
                        />
                    </div>
                </div>


                <div className="row mt-2">

                    <div className='col-md-6'>
                        <FromFieldText
                            star="*"
                            label="Contact Person Name"
                            placeholder="Contact Person Name"
                            inputValue={contactPersonName}
                            setInputValue={setContactPersonName}
                            setErrorMsg={ErrContactPersonName}
                        />
                    </div>
                    <div className="col-6">
                        <FromFieldText
                            star="*"
                            label="Contact Number"
                            placeholder="Contact Number"
                            inputValue={contactNumber}
                            setInputValue={setContactNumber}
                            setErrorMsg={ErrContactNumber}
                        />
                    </div>
                </div>

                <div className="row mt-2">

                    <div className='col-md-6'>
                        <FromFieldText
                            star="*"
                            label="Email Id"
                            placeholder="Email ID"
                            inputValue={userEmailID}
                            setInputValue={setUserEmailID}
                            setErrorMsg={ErrUserEmailID}
                        />
                    </div>
                    <div className="col-6">
                        <FromFieldText
                            star="*"
                            label="Mobile Number"
                            placeholder="Mobile Number"
                            inputValue={userMobileNo}
                            setInputValue={setUserMobileNo}
                            setErrorMsg={ErrUserMobileNo}
                        />
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-md-3">
                        <label>Service under </label><span className="star error"> *</span>
                    </div>
                    <div className='col-md-3'>
                        <select className="form-select form-select-sm" onChange={handleServices}
                                value={servicesList}>
                            <option value="">Select Service under</option>
                            <option value="Warranty">Warranty</option>
                            <option value="AMC_Coverage">AMC Coverage</option>
                            <option value="Commercial">Commercial</option>
                            <option value="Free_of_Cost">Free of Cost</option>
                            <option value="Not_Applicable">Not Applicable</option>
                            <option value="Others">Others</option>
                        </select>
                        <span className="invalid-feedback"> {ErrServicesList} </span>
                    </div>

                    <div className="col-6">
                        <FromFieldDate
                            label="Expected Date of Return" star="*" placeholder="YYYY-MM-DD"
                            inputValue={returnDate}
                            setInputValue={setReturnDate}
                            setErrorMsg={ErrReturnDate}
                        />
                    </div>
                </div>

                <div className="row mt-2">

                    <div className='col-md-6'>
                        <FromFieldText
                            star="*"
                            label="Estimated Cost if Any"
                            placeholder="Estimated Cost"
                            inputValue={estimatedCost}
                            setInputValue={setEstimatedCost}
                            setErrorMsg={ErrEstimatedCost}
                        />
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col-md-12 d-flex justify-content-center">
                        <button type="submit" className="btn btn-primary btn-sm">Create ER Assignment</button>
                    </div>
                </div>

            </form>
        </div>
    );
}

export default CreateNewIRAssignmentOthers;