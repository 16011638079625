import React, {useEffect, useState} from 'react'
import {useLocation} from "react-router-dom";
import FromFieldText from '../../atoms/FromFieldText';
import {
    getCompanyProfileList,
    getDepartmentListForMaster,
    getDivisionList,
    getSubDepartmentInfo,
    saveSubDepartment, getDepartmentGroupListAdmin
} from '../../services/user.service';
import * as CryptoJS from "crypto-js";

const TEXT_REGEX = /^[a-zA-Z0-9-_ ]{1,155}$/;

function AddSubDepartment() {

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    const [companyId, setCompanyId] = useState('');
    const [ErrCompanyId, setErrCompanyId] = useState(false);

    const [departmentID, setDepartmentId] = useState('');
    const [ErrDepartmentId, setErrDepartmentId] = useState(false);

    const [division, setDivision] = useState(0);
    const [ErrDivision, setErrDivision] = useState(false);

    const [departmentname, setDepartmentName] = useState('');
    const [ErrDepartmentName, setErrDepartmentName] = useState(false);

    const [departmentshortname, setDepartmentShortName] = useState('');
    const [ErrDepartmentShortName, setErrDepartmentShortName] = useState(false);

    const [DepartmentGrouping, setDepartmentGrouping] = useState('');
    const [ErrDepartmentGrouping, setErrDepartmentGrouping] = useState(false);

    const [companies, setCompanies] = useState([]);

    const [departmentnames, setDepartmentnames] = useState([]);

    const [divisions, setDivisions] = useState([]);
    const [grouping, setGrouping] = useState([]);
    const resetUserInfo = function () {
        setDepartmentName('');
        setDepartmentShortName('');
        setCompanyId('');
        setDepartmentId('');
        setDepartmentGrouping('');
    }

    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const RoleId = query.get('roleid');

    useEffect(() => {
        if (RoleId) {
            const decryptedBytes = CryptoJS.AES.decrypt(RoleId.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
            const decrypt_data = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
            getDepartmentData(decrypt_data.roleid);
        }

        const EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        const oData = {'token': EncryptData};

        getCompanyProfileList(oData).then(res => {
            if (res?.data?.data?.length) setCompanies(res.data.data);
        });

        getDepartmentListForMaster(oData).then(res => {
            if (res?.data?.data?.length) setDepartmentnames(res.data.data);
        });

        getDepartmentGroupListAdmin(oData).then(res => {
            if (res?.data?.data?.length) setGrouping(res.data.data);
        });

    }, [RoleId]);

    const getDepartmentData = function (DivisionId) {
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': {'divis_id': DivisionId, 'company_id': companyId},
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData1 = {'token': EncryptData}
        getSubDepartmentInfo(oData1).then(function (res) {
            if (res?.data?.data) {
                var epartInfo = res.data.data[0];
                setCompanyId(epartInfo.company_id);
                setDepartmentId(epartInfo.department_id);
                setDivision(epartInfo.division_id);
                setDepartmentName(epartInfo.sub_department_name);
                setDepartmentShortName(epartInfo.sub_department_short_name);
                setDepartmentGrouping(epartInfo.department_group);
            }
            getDivisionList(oData1).then(res => {
                if (res?.data?.data?.length) setDivisions(res.data.data);
            });
        })
    }
    const handleGrouping = (e) => {
        let dep_id = e.target.value;
        setDepartmentGrouping(dep_id);

        // Fetch division list related to the department's company
        let EncryptData1 = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': {'company_id': companyId}, // Use companyId here
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

        let oData1 = {'token': EncryptData1};

        getDepartmentGroupListAdmin(oData1).then(res => {
            if (res?.data?.data?.length) {
                setGrouping(res.data.data);
            } else {
                setGrouping([]);
            }
        });
    }
    const handleCompanyId = (e) => {
        let comp_id = e.target.value;
        setCompanyId(comp_id);

        let EncryptData1 = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': {'company_id': comp_id},
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

        let oData1 = {'token': EncryptData1};

        // Fetch the department list
        getDepartmentListForMaster(oData1).then(res => {
            if (res?.data?.data?.length) {
                setDepartmentnames(res.data.data);
            } else {
                setDepartmentnames([]);
            }
        });
        getDepartmentGroupListAdmin(oData1).then(res => {
            if (res?.data?.data?.length) {
                setGrouping(res.data.data);
            } else {
                setGrouping([]);
            }
        });
    }

    const handleDepartmentId = (e) => {
        let dep_id = e.target.value;
        setDepartmentId(dep_id);

        // Fetch division list related to the department's company
        let EncryptData1 = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': {'company_id': companyId}, // Use companyId here
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

        let oData1 = {'token': EncryptData1};

        getDepartmentListForMaster(oData1).then(res => {
            if (res?.data?.data?.length) {
                setDivisions(res.data.data);
            } else {
                setDivisions([]);
            }
        });
    }

    const handleDivision = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setDivision(e.target.value);
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        var valid_Name = true;

        if (!TEXT_REGEX.test(companyId)) {
            setErrCompanyId('company is required');
            valid_Name = false;

        } else {
            setErrCompanyId('');
        }
        if (!TEXT_REGEX.test(departmentID)) {
            setErrDepartmentId('Department name is required');
            valid_Name = false;

        } else {
            setErrCompanyId('');
        }
        if (!TEXT_REGEX.test(departmentname)) { //alert(name);
            setErrDepartmentName('Sub Department name is required');
            valid_Name = false; //alert(valid_Name);

        } else {
            setErrDepartmentName('');
        }
        if (!TEXT_REGEX.test(departmentshortname)) { //alert(companyId);
            setErrDepartmentShortName('Sub Department name is required');
            valid_Name = false;

        } else {
            setErrDepartmentShortName('');
        }
        if (!TEXT_REGEX.test(departmentshortname)) { //alert(companyId);
            setErrDepartmentShortName('Sub Department short name is required');
            valid_Name = false;

        } else {
            setErrDepartmentShortName('');
        }
        if (!TEXT_REGEX.test(departmentshortname)) { //alert(companyId);
            setErrDepartmentGrouping('Department grouping is required');
            valid_Name = false;

        } else {
            setErrDepartmentGrouping('');
        }
        if (valid_Name) {
            saveData();
        } else {
            return false;
        }
    }

    const saveData = function () {

        if (RoleId !== '' && RoleId !== null) {
            const decryptedBytes = CryptoJS.AES.decrypt(RoleId.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
            // Convert the decrypted bytes to a string
            const decrypt_data = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
            const decryptedDepID = decrypt_data.roleid;
            var obj = {
                'dep_ID': decryptedDepID,
                'department_id': departmentID,
                'sub_department_name': departmentname,
                'sub_department_short_name': departmentshortname,
                'department_group': DepartmentGrouping,
                'company_id': companyId,
                'division_id': 0,
                'status': '1',
                'created_by': sessionStorage.getItem('LoggedInUserId'),
            }
        } else {
            var obj = {
                'department_id': departmentID,
                'sub_department_name': departmentname,
                'sub_department_short_name': departmentshortname,
                'department_group': DepartmentGrouping,
                'company_id': companyId,
                'division_id': 0,
                'status': '1',
                'created_by': sessionStorage.getItem('LoggedInUserId'),
            }
        }

        try {
            //encrypted data
            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': obj,
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData = {'token': EncryptData}
            saveSubDepartment(oData).then(function (res) {
                //console.log(res);
                if ((typeof (res.data.errors) != "undefined") && res.data.errors.errno > 0) {
                    setErrMsg(res.data.message);
                    setSuccess(false);
                } else {
                    setSuccess(res.data.message);
                    resetUserInfo();
                }
            });

            //setValidName(false);
        } catch (err) {
            setSuccess(false);
            if (!err?.data) {
                console.log(err);
                setErrMsg('No Server Response');
            } else if (err.data?.status === 409) {
                setErrMsg('data already exist');
            } else {
                setErrMsg('Insert/Update Failed');
            }
            //elementRef.current.focus();
        }
    }
    return (
        <div className="container">

            <div className="row">
                <div className="col-6">
                    <h5>Add Sub Control Department</h5>
                </div>
                <div className="invalid-feedback" role="alert">
                    <i className="fa fa-info-circle"></i>
                    Please Fill all mandatory field.
                </div>

                {success ? (
                    <div className="alert alert-success" role="alert"><i
                        className="bi bi-check-circle-fill"></i>{success}</div>
                ) : (errMsg ? (<div className="alert alert-danger" role="alert"><i
                        className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>) : ('')
                )}
            </div>

            <div className='row '>
                <div className='col-12'>&nbsp;</div>

            </div>
            <form onSubmit={handleSubmit}>
                <div className="row form-fields mt-2">
                    <div className="label col-3">
                        <label>Company Name</label><span className="star error"> *</span>
                    </div>

                    <div className="item col-3">

                        <select onChange={handleCompanyId} className="form-select form-select-sm mb-3">
                            <option value="">Select Company</option>
                            {companies && companies.length > 0 ? (
                                companies.map((option) => (
                                    <option value={option.company_id}
                                            selected={(option.company_id == companyId) ? "selected" : ''}>{option.company_name}</option>
                                ))
                            ) : (
                                <option value="">No Data</option>
                            )}
                        </select><span className="invalid-feedback"> {ErrCompanyId} </span>
                    </div>
                </div>

                <div className="row form-fields mt-2">
                    <div className="label col-3">
                        <label>Department Name</label><span className="star error"> *</span>
                    </div>

                    <div className="item col-3">

                        <select onChange={handleDepartmentId} className="form-select form-select-sm mb-3">
                            <option value="">Select Department</option>
                            {departmentnames && departmentnames.length > 0 ? (
                                departmentnames.map((option) => (
                                    <option value={option.id}
                                            selected={(option.id == departmentID) ? "selected" : ''}>{option.department_name}</option>
                                ))
                            ) : (
                                <option value="">No Data</option>
                            )}
                        </select><span className="invalid-feedback"> {ErrDepartmentId} </span>
                    </div>
                </div>

                <div className="row mb-2 mt-2">
                    <div className="col-6 mb-2">
                        <FromFieldText star="*"
                                       label="Sub Control Department Name"
                                       inputValue={departmentname}
                                       setInputValue={setDepartmentName}
                                       setErrorMsg={ErrDepartmentName}
                        />
                    </div>
                    <div className="col-6"></div>
                    <div className="col-6">
                        <FromFieldText star="*"
                                       label="Sub Department Short Name"
                                       inputValue={departmentshortname}
                                       setInputValue={setDepartmentShortName}
                                       setErrorMsg={ErrDepartmentShortName}
                        />
                    </div>
                </div>
                <div className="row form-fields mt-2">
                    <div className="label col-3">
                        <label>Department Group</label><span className="star error"> *</span>
                    </div>
                    <div className="item col-3">
                        <select
                            className="form-select form-select-sm mb-3"
                            value={DepartmentGrouping}  // Bind value here
                            onChange={handleGrouping}   // Handle change here
                        >
                            <option value="">Select department grouping</option>
                            {grouping && grouping.length > 0 ? (
                                grouping.map((option) =>
                                    <option value={option.id}
                                            selected={(option.id == DepartmentGrouping) ? "selected" : ''}>{option.department_group_name}</option>
                                )
                            ) : (
                                <option value="">No Data</option>
                            )}
                        </select>
                        <span className="invalid-feedback">{ErrDepartmentGrouping}</span>
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-3"></div>
                    <div className="col-6">
                        <button className="btn btn-primary btn-md"> SAVE</button>
                    </div>
                    <div className="col-3"></div>
                </div>
            </form>
        </div>
    )
}

export default AddSubDepartment


